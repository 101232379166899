@import "../../scss/main.scss";

.account-info {
  p {
    @include sm-paragraph;
    font-style: italic;
    span {
      color: map-get($colors, "mediumblue");
    }
  }
}

.account-block {
  width: 100%;
  margin-top: 5rem;

  h2 {
    @include t-body;
    color: map-get($colors, "mediumblue");
    border-bottom: solid 1px map-get($colors, "darkgrey");
  }

  &.licenses {
    .licenses-header {
      display: flex;
      gap: 3rem;
      border-bottom: solid 1px map-get($colors, "darkgrey");

      h2 {
        display: inline-block;
        border-bottom: 0px;
      }
      span {
        font-size: 1.5rem;
        font-style: italic;
        margin-right: 4rem;
        font-weight: 300;
        color: map-get($colors, "darkgrey");
        &.icon {
          margin-right: 0;
          &:hover,
          &:active {
            cursor: pointer;
          }
        }
      }
      input[type="text"] {
        border: none;
        width: 32rem;
        margin-right: 1rem;
        background-color: map-get($colors, "lightgrey");
        @include t-body-sm;
        color: map-get($colors, "darkgrey");
        font-style: italic;

        @media only screen and (max-width: 400px) {
          width: auto;
        }
      }
    }
  }
  table {
    width: 100%;
    tr {
      td {
        padding: 1rem 0;
      }
    }
  }
  &.licenses {
    border-bottom: solid 1px map-get($colors, "darkgrey");
    padding-bottom: 2rem;
    table tr td {
      &:last-of-type {
        text-align: right;
        a {
          @include t-body-sm;
          text-decoration: none;
          font-style: italic;
          .icon-arrow {
            margin-left: 1rem;
          }
        }
      }
      // &:nth-child(1) {
      @include t-body-sm;
      color: map-get($colors, "darkgrey");
      // }

      span#status {
        @include t-body-sm;
        text-decoration: none;
        font-style: italic;
        color: map-get($colors, "mediumblue");
        display: inline-block;
        + .icon-arrow {
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
  }
  .fingerprint-row {
    display: flex;
    .avatar {
      width: 10rem;
      position: relative;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .fingerprint-name {
    position: relative;
    padding-left: 3rem;
    span {
      display: block;
      width: 100%;
      position: relative;
      &.name {
        @include t-header-m;
        color: map-get($colors, "mediumblue");
        margin-top: -1rem;
      }
    }
    a {
      @include t-body;
      color: map-get($colors, "darkgrey");
      display: block;
      text-decoration: none;
    }
  }
  &.my-profile {
    @include t-body-sm;
    color: map-get($colors, "darkgrey");
    > * {
      display: block;
      width: 100%;
    }

    > a {
      text-decoration: none;
      margin-top: 1rem;
    }
  }
}

.licenses-enter-key {
  display: flex;
  align-items: center;
}

.licenses-header {
  margin-bottom: 2rem;

  @media only screen and (max-width: 1240px) {
    display: block !important;

    h2 {
      margin-bottom: 2rem;
    }
  }
}

button.more-assessments {
  float: left;
  display: block;
  position: relative;
  width: auto;
  margin-bottom: 2rem;
}

.logout-wrapper {
  position: relative;
  width: 100%;
  > a {
    @include t-body-sm;
    text-decoration: none;
  }
  > span {
    @include t-body-sm;
    color: map-get($colors, "lightblue");
    cursor: pointer;
    pointer-events: all;
  }
}

.access-code {
  span {
    font-size: 1.5rem;
    font-style: italic;
    margin-right: 4rem;
    font-weight: 300;
    color: map-get($colors, "darkgrey");

    &.icon {
      &:hover,
      &:active {
        cursor: pointer;
      }
    }
  }

  input[type="text"] {
    border: none;
    width: 32rem;
    margin-right: 1rem;
    background-color: map-get($colors, "lightgrey");
    @include t-body-sm;
    color: map-get($colors, "darkgrey");
    font-style: italic;
    padding: 0.3em 1em;
    border: 2px solid map-get($colors, "mediumblue");
  }
}

.account-info {
  margin-top: 30px;
}
