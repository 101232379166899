.btn-primary {
  @include button-primary;
}

.btn-white {
  @include button-white;

  &.no-wrap {
    white-space: nowrap;
  }
}

.btn-borderless {
  @include borderless-button;
}

div#modal {
  position: relative;
  z-index: 1;
}

.delete-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: map-get($colors, "overlay-background");

  .delete-modal {
    padding: 2rem;
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: map-get($colors, "navyblue");
    border: 2px solid white;
    p {
      @include sm-paragraph;
      color: white;
      font-size: 1.5rem;
    }
    button {
      margin-top: 2rem;
    }
  }
}

$navWidth: 20vw;

p.standard {
  @include sm-paragraph;
}

span#forgot-password {
  @include t-body-sm();
}

div.alert {
  padding: 2rem 1rem;
  z-index: 1000;
}
div.alert ul li {
  @include sm-paragraph;
  color: #842029;
  &::marker {
    display: none;
  }
}

.absolute-message {
  position: fixed;
  width: 100%;
  text-align: center;
}
button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

span.error {
  @include t-body-sm;
}
span.logo {
  &:hover {
    cursor: pointer;
  }
}

div.lapin-fingerprint div.container-medium form#a_few_questions p {
  font-size: 2rem;
}

form.fingerprint-form div.explainer {
  font-size: 1.5rem;
}

// Prevent overlap with form
@media only screen and (max-width:1560px){
  .two-shapes{
    display:none !important;
  }
}