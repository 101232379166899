$colors: (
  "white": #fff,
  "black": #000,
  "overlay-background": rgba(0, 0, 0, 0.7),
  "lightgrey": #f7f5f2,
  "mediumgrey": #d0d3d6,
  "darkgrey": #8b9197,
  "lightblue": #82aaf9,
  "mediumblue": #3c78e6,
  "darkblue": #33337f,
  "navyblue": #1a1a42,
  "red": #f07b7b,
);

$sizes: (
  "shape": 285px,
  "sm-shape": 198px,
  "mws": 600px,
  "mwm": 700px,
  "mwl": 1075px,
);

$media: (
  "mw400": "only screen and (min-width:400px)",
  "mw500": "only screen and (min-width:500px)",
  "mw600": "only screen and (min-width:600px)",
  "mw700": "only screen and (min-width:700px)",
  "mw783": "only screen and (min-width:783px)",
  "mw800": "only screen and (min-width:800px)",
  "mw900": "only screen and (min-width:900px)",
  "mw1000": "only screen and (min-width:1000px)",
  "mw1050": "only screen and (min-width:1050px)",
  "mw1100": "only screen and (min-width:1100px)",
  "mw1150": "only screen and (min-width:1150px)",
  "mw1200": "only screen and (min-width:1200px)",
  "mw1250": "only screen and (min-width:1250px)",
  "mw1300": "only screen and (min-width:1300px)",
  "mw1350": "only screen and (min-width:1350px)",
  "mw1400": "only screen and (min-width:1400px)",
  "mw1450": "only screen and (min-width:1450px)",
  "mw1500": "only screen and (min-width:1500px)",
  "mw1550": "only screen and (min-width:1550px)",
  "mw1600": "only screen and (min-width:1600px)",
  "mw1700": "only screen and (min-width:1700px)",
  "mw1800": "only screen and (min-width:1800px)",
  "mw1900": "only screen and (min-width:1900px)",
);

$type-body: "Ginto", Arial, sans-serif;

//SHADOWS

$sh-primary: 0px 0px 16px rgba(0, 0, 0, 0.16);
