@import "../../../scss/main.scss";

svg {
  max-width: 100%;
}

.fingerprint-svg {
  position: absolute;
  top: 0;
  left: -56px;
  width: 1050px;
  padding: 20px 20px 20px 0;

  svg {
    position: relative;
    z-index: 5;
  }
}

.fingerprint-svg-bg {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33337f;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  border-radius: 0 310px 310px 0;

  @include media(mobile) {
    display: none;
  }
}

.final-fingerprint {
  .fingerprint-svg-bg {
    background-color: #1a1a42;
  }
}

.initial-fingerprint-wrapper {
  min-height: 520px;
}

.lapin-fingerprint {
  min-height: 100vh;
  padding: 5rem;
  overflow-x: hidden; // Hide fingerprint icon overflow

  @include media(mobile) {
    padding: 3rem;
  }

  &.bg-lightgrey {
    background-color: map-get($colors, "lightgrey");
  }

  &.bg-blue,
  &.bg-darkblue,
  &.bg-navy {
    color: map-get($colors, "lightgrey");
  }

  &.bg-blue {
      background-color: map-get($colors, "mediumblue");
  }

  &.bg-darkblue {
      background-color: map-get($colors, "darkblue");
  }

  &.bg-navy {
      background-color: map-get($colors, "navyblue");
  }

  &.no-shapes ~ .two-shapes {
    opacity: 0;
  }

  &.confirmation,
  &.not-very-much {
    .fingerprint-form > p {
      color: map-get($colors, "lightblue");
    }
  }
}

.final-narrative-button-row {
  .col {
    @include media(mobile) {
      flex: 0 auto;
    } 
  }
}

.stretch {
  .fingerprint-content {
    max-width: 100%;
  }

  .craft-narrative {
    max-width: 700px;
  }
}

.fingerprint-content {
  max-width: 700px;
  margin: 0 auto;
  font-size: 2rem;
}

.fingerprint-svg svg {
  @include media(mobile) {
    display: none;
  }
}

.fingerprint-bg {
  position: absolute;
  top: -432px;
  right: -1002px;
  max-width: 1400px;

  @include media(mobile) {
    top: -189px;
    right: 72px;
    max-width: 800px;
  }
}

.fingerprint-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media(mobile) {
    display: block;
  }

  & + .fingerprint-content {
    margin-top: 10rem;

    @include media(mobile) {
      margin-top: 5rem;
    }
  }
}

.bg-lightgrey {
  .fingerprint-view-header {
    & + .fingerprint-content {
      margin-top: 5rem;
    }
  }
}

.fingerprint-logo {
  cursor: pointer;

  & + .progress-bar-wrapper {
    @include media(mobile) {
      margin-top: 5rem;
    }
  }
}

div.purchase-steps {
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;

  > a {
    width: auto;
    text-decoration: none;
    color: map-get($colors, "mediumgrey");
    pointer-events: none;
    @include t-body-sm;
  }
  .line {
    display: block;
    height: 1px;
    width: 7%;
    background-color: map-get($colors, "mediumgrey");
    transform: translateY(2px);
  }
  &.quantity {
    > a.step-quantity {
      color: map-get($colors, "mediumblue");
    }
  }
  &.payment {
    > a.step-quantity {
      color: map-get($colors, "darkgrey");
      pointer-events: all;
    }
    > a.step-payment {
      color: map-get($colors, "mediumblue");
      ~ .line.payment-line {
        background-color: map-get($colors, "darkgrey");
      }
    }
  }
  &.confirmation {
    > a.step-quantity,
    > a.step-payment {
      color: map-get($colors, "darkgrey");
      pointer-events: all;
    }
    > a.step-confirmation {
      color: map-get($colors, "mediumblue");
      ~ .line.confirmation-line {
        background-color: map-get($colors, "darkgrey");
      }
    }
    div.fingerprint-form-navigation {
      justify-content: flex-start;
      a.back-button {
        margin-left: 3rem;
      }
    }
  }
  &.completion {
    > a.step-quantity,
    > a.step-payment,
    > a.step-confirmation {
      color: map-get($colors, "darkgrey");
      pointer-events: all;
    }
    > a.step-completion {
      color: map-get($colors, "mediumblue");
      ~ .line.completion-line {
        background-color: map-get($colors, "darkgrey");
      }
    }
  }
}

.lapin-footer {
  .copyright {
    color: map-get($colors, "darkgrey");
    display: block;
    margin-top: 10rem;
    margin-bottom: 4rem;
    font-size: 12px;
  }
}
