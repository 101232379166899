@import "../../../scss/main.scss";

.purchase-details-confirmation {
  &:first-of-type {
    margin-top: 10rem;
  }
  &:last-of-type {
    margin-bottom: 10rem;
  }
}

.purchase-details {
  .purchase-detail-header {
    padding: 0 0 1.25rem 0;
    margin-bottom: 1.25rem;
    align-items: center;
    border-bottom: solid 1px #8b9197;
    .col {
      padding: 0 0.5rem 0 0;
    }
    h2 {
      @include t-body;
      color: map-get($colors, "mediumblue");
      margin: 0;
    }
    button {
      @include t-body-sm;
      color: map-get($colors, "lightblue");
      margin-top: 0;
      padding: 0px;
      border: none;
      &:hover {
        background-color: transparent;
        color: map-get($colors, "navyblue");
      }
    }
  }

  .purchase-details-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin-left: -1rem;
  }

  .purchase-detail {
    @include t-body-sm;
    > span {
      display: block;
      position: relative;
      width: 100%;
      .no-break {
        white-space: nowrap;
      }
    }
    span.label {
      color: #8b9197;
      margin-bottom: 1rem;
    }
    span.value {
      color: #1a1a42;
    }
    &:nth-child(4) {
      width: 50%;
      margin-top: 3rem;
    }
  }
}

.bottom-options.row {
  margin-bottom: 10rem;
}
