@import "../../../scss/main.scss";

.back-button {
  @include t-body-sm;
  text-decoration: none;
  color: map-get($colors, "lightblue");
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    color: #82aaf9;
  }
}

.explainer {
  @include t-body-sm;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

video::cue {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 30px;
}

.fingerprint-form {
  position: relative;

  .loader {
    position: absolute;
    top: 0;
    left: -50px;
  }

  h2 {
    @include t-header-m;
    margin: 0;
  }

  > p {
    // @include t-body-sm;
    margin-bottom: 3.5rem;
    &:first-of-type {
      margin-top: 4rem;
    }
    &:last-of-type {
      margin-bottom: 5rem;
    }
  }
  .dropdown-wrapper {
    width: 100%;
    display: block;
    margin-bottom: 4rem;
    &:first-of-type {
      margin-top: 4rem;
    }
    .dropdown {
      width: 100%;
      border-bottom: solid 2px map-get($colors, "mediumblue");
      button.dropdown-toggle {
        @include t-body;
        color: map-get($colors, "lightgrey");
        text-align: left;
        background-color: transparent;
        border: 0px;
        width: 100%;
        display: block;
        padding-bottom: 1rem;
        svg {
          position: absolute;
          width: 1.1rem;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
        &:after {
          display: none;
        }
      }
      .dropdown-menu {
        background-color: map-get($colors, "darkblue");
        width: 100%;

        @include t-body;
        max-height: 25rem;
        overflow-y: auto;

        a {
          color: map-get($colors, "lightgrey");
          &:hover,
          &:focus {
            background-color: map-get($colors, "navyblue");
          }
        }
        &.show {
          width: 102%;
          transform: translate(-5px, -44px) !important;
        }
      }
    }
    &.has-option {
      display: flex;
      gap: 3rem;
      .dropdown {
        width: 25%;
      }
      .form-group {
        width: 75%;
      }
    }
  }
  .form-group {
    &:first-of-type {
      margin-top: 5rem;
    }
    input.form-control {
      @include t-body;
      color: map-get($colors, "lightgrey");
      &::placeholder {
        color: map-get($colors, "lightgrey");
        font-weight: 300;
        font-style: italic;
      }
    }
  }
  .textarea-wrapper {
    textarea {
      @include t-body;
      width: 100%;
      height: 20rem;
      background-color: transparent;
      border: none;
      border-bottom: solid 2px map-get($colors, "mediumblue");
      resize: none;
      color: map-get($colors, "lightgrey");
      margin: 5rem 0;
      padding: 2rem;
      &::placeholder {
        color: map-get($colors, "lightgrey");
        font-style: italic;
      }
    }
  }
  .fingerprint-form-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 2rem;

    &.next-only {
      margin-top: 5rem;
      justify-content: flex-end;
    }

    span.next-button {
      cursor: pointer;
      white-space: nowrap;
    }
    a.next-button,
    span.next-button {
      @include button-white;
      margin-top: 0;
      text-decoration: none;
      .icon-arrow-left {
        transform: rotate(180deg);
        margin-left: 0.25rem;
        margin-right: 1rem;
        display: inline-flex;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .nav-message {
      font-style: italic;
      color: map-get($colors, "lightgrey");
    }
    &.back-order-9 {
      a.back-button {
        flex-order: 9;
        order: 9;
      }
    }
    &.left-close {
      justify-content: flex-start;
      gap: 5rem;
    }
  }
  .video-wrapper {
    position: relative;
    margin: 3rem 0 4rem;
    video {
      display: block;
      width: 100%;
    }
    .play-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: all 0.5s;
      cursor: pointer;
      * {
        pointer-events: none;
      }
      > span {
        @include t-body-sm;
        display: block;
        margin-top: 2rem;
      }
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.radio-wrapper {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  @include t-body;
  text-transform: uppercase;
  border-bottom: solid 2px map-get($colors, "mediumblue");
  span.radio-option {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
    &.mapping {
      text-transform: none;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      ~ label {
        transition: all 0.5s;
        pointer-events: none;
        &:before {
          content: "";
          display: block;
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          border: solid 2px map-get($colors, "lightblue");
          margin-right: 1.2rem;
          float: left;
          transform: translateY(8px);
          transition: all 0.5s;
        }
      }
      &:hover ~ label:before {
        border-color: map-get($colors, "lightgrey");
      }
      &:checked ~ label {
        color: map-get($colors, "lightblue");
        &:before {
          border-color: map-get($colors, "lightgrey");
          background-color: map-get($colors, "lightgrey");
        }
      }
    }
  }
}
