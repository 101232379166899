@import "../../scss/main.scss";

.shape-1,
.shape-2,
.shape-3,
.shape-4,
.shape-5,
.shape-6 {
  display: none;
  width: map-get($sizes, "shape");
  height: map-get($sizes, "shape");
  top: 0;
  position: absolute;
  transition: all 0.5s;
  z-index: 1;

  @media #{map-get($media, "mw900")} {
    display: block;
  }

  &.x-right {
    right: 0;
  }

  &.x-left {
    left: 0;
  }

  &.type-vector {
    z-index: 2;
  }

  &.circle {
    .shape.circle {
      opacity: 1;
    }
  }

  &.square {
    .shape.square {
      opacity: 1;
    }
  }

  &.densemaze {
    .shape.dense-maze {
      opacity: 1;
    }
  }
  &.lightmaze {
    .shape.light-maze {
      opacity: 1;
    }
  }
  &.outermaze {
    .shape.outer-maze {
      opacity: 1;
    }
  }

  &.lines {
    .shape.lines {
      opacity: 1;
    }
  }

  .shape {
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s;
    transition-delay: all 0.5s;
    &.css-shape,
    &.svg-shape {
      display: block;
      width: map-get($sizes, "shape");
      height: map-get($sizes, "shape");
    }

    &.css-shape {
      &.circle {
        border-radius: 50%;
      }
    }
    &.svg-shape {
      svg {
        width: 100%;
        width: 100%;
      }
    }
  }

  &.color-grey {
    &.type-solid {
      .css-shape {
        background-color: map-get($colors, "mediumgrey");
      }
    }
    &.type-outline {
      .css-shape {
        border: solid 5px map-get($colors, "mediumgrey");
      }
    }
    &.type-vector {
      .svg-shape {
        svg path {
          stroke: map-get($colors, "mediumgrey");
        }
      }
    }
  }

  &.color-blue {
    &.type-solid {
      .css-shape {
        background-color: map-get($colors, "mediumblue");
      }
    }
    &.type-outline {
      .css-shape {
        border: solid 5px map-get($colors, "mediumblue");
      }
    }
    &.type-vector {
      .svg-shape {
        svg path {
          stroke: map-get($colors, "mediumblue");
        }
      }
    }
  }

  &.color-lightblue {
    &.type-solid {
      .css-shape {
        background-color: map-get($colors, "lightblue");
      }
    }
    &.type-outline {
      .css-shape {
        border: solid 5px map-get($colors, "lightblue");
      }
    }
    &.type-vector {
      .svg-shape {
        svg path,
        svg line {
          stroke: map-get($colors, "lightblue");
        }
      }
    }
  }

  &.color-white {
    &.type-solid {
      .css-shape {
        background-color: map-get($colors, "white");
      }
    }
    &.type-outline {
      .css-shape {
        border: solid 5px map-get($colors, "white");
      }
    }
    &.type-vector {
      .svg-shape {
        svg path {
          stroke: map-get($colors, "white");
        }
      }
    }
  }
}

.shape-wrapper {
  position: relative;
  width: calc(#{map-get($sizes, "shape")} * 1.5);
  height: map-get($sizes, "shape");
}

@keyframes bottom {
  100% {
    transform: translateY(300%);
  }
}

.six-shapes {
  position: absolute;
  right: 0;
  top: -50%;
  width: calc(#{map-get($sizes, "shape")} * 1.75);
  //height: map-get($sizes, "shape");
  transition: all 0.5s;
  .shape-wrapper {
    height: 100vh;
    width: 100%;
    .shape-1 {
      animation: bottom 4s ease-in;
      left: 0;

      top: calc((#{map-get($sizes, "shape")} / 3) * -1);
    }
    .shape-2 {
      animation: bottom 4s ease-in;
      right: 0;

      top: calc((#{map-get($sizes, "shape")} / 3) * 1);
    }
    .shape-3 {
      animation: bottom 4s ease-in;
      left: 0;

      top: calc((#{map-get($sizes, "shape")} / 10) * 11);
    }
    .shape-4 {
      animation: bottom 4s ease-in;
      right: 0;

      top: calc((#{map-get($sizes, "shape")} / 3) * 5);
    }
    .shape-5 {
      animation: bottom 4s ease-in;
      right: 0;

      top: calc((#{map-get($sizes, "shape")} / 3) * -3);
    }
    .shape-6 {
      animation: bottom 4s ease-in;
      right: 0;

      top: calc((#{map-get($sizes, "shape")} / 3) * -5);
    }
  }
}

.two-shapes {
  position: absolute;
  right: 0;
  width: calc(#{map-get($sizes, "shape")} * 1.5);
  height: map-get($sizes, "shape");
  transition: all 0.5s;
  &.y-top {
    top: 0;
  }
  &.y-bottom {
    top: calc(100% - #{map-get($sizes, "shape")});
  }
  // "y-top s1-square s1-grey s1-left s1-solid s2-circle s2-blue s2-right s2-outline"
  .s1-square {
    .square {
      z-index: 1;
      opacity: 1;
    }
  }
  .s1-circle {
    .circle {
      z-index: 1;
      opacity: 1;
    }
  }
  &.fixed {
    position: fixed;
  }
}

@media only screen and (max-height: 1199px) {
  .two-shapes.fixed {
    display: none; // Dont overlap the fingerprint image
  }
}
