//BODY

@mixin t-body {
  font-weight: normal;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin t-body-sm {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin t-body-xs {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin sm-paragraph {
  @include t-body-sm;
  width: 100%;
  position: relative;
  float: left;
  color: map-get($colors, "darkgrey");
  a {
    text-decoration: none;
    color: map-get($colors, "mediumblue");
    transition: all 0.25s;
    &:hover,
    &:focus {
      color: map-get($colors, "darkblue");
    }
  }
}

$mobileBreakPoint: 767px;
$tabletBreakPoint: 1024px;

@mixin media($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $mobileBreakPoint) {
      @content;
    }
  }

  @if $breakpoint==mobileAndTablet {
    @media only screen and (max-width: $tabletBreakPoint) {
      @content;
    }
  }

  @if $breakpoint==tabletAndDesktop {
    @media only screen and (min-width: $tabletBreakPoint + 1) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media only screen and (min-width: $mobileBreakPoint + 1) {
      @content;
    }
  }
}

//HEADERS

@mixin t-header-xxl {
  font-weight: normal;
  font-size: 8rem;
  line-height: 10rem;
}

@mixin t-header-xl {
  font-weight: normal;
  font-size: 6rem;
  margin-bottom: 4rem;

  @include media(mobile) {
    font-size: 4rem;
  }
}

@mixin t-header-l {
  font-weight: normal;
  font-size: 4rem;
}

@mixin t-header-m {
  font-weight: normal;
  font-size: 3rem;
}

@mixin t-header-sm {
  font-weight: bold;
  font-size: 2rem;
}

@mixin button-primary {
  @include t-body;
  background-color: transparent;
  border: solid 2px map-get($colors, "mediumblue");
  border-radius: 0px;
  color: map-get($colors, "mediumblue");
  padding: 0.7rem 1.5rem 1rem;
  transition: all 0.5s;
  white-space: nowrap;

  .icon {
    margin-left: 1rem;
    &.icon-link {
      stroke: rgba(255, 255, 255, 0);
    }
  }
  .icon:not(.icon-link):not(.has-color) svg g path {
    stroke: map-get($colors, "mediumblue");
    transition: all 0.25s;
  }
  &:hover {
    background-color: map-get($colors, "mediumblue");
    border-color: map-get($colors, "mediumblue");
    color: map-get($colors, "lightgrey");
    .icon svg g path {
      stroke: map-get($colors, "lightgrey");
      transition: all 0.25s;
    }
  }
  &.white {
    border-color: map-get($colors, "lightgrey");
    color: map-get($colors, "lightgrey");
    .icon svg g path {
      stroke: map-get($colors, "lightgrey");
    }
    &:hover {
      background-color: map-get($colors, "lightgrey");
      border-color: map-get($colors, "lightgrey");
      color: map-get($colors, "mediumblue");
      .icon svg g path {
        stroke: map-get($colors, "mediumblue");
        transition: all 0.25s;
      }
    }
  }
}

@mixin button-white {
  @include button-primary;
  border-color: map-get($colors, "lightgrey");
  color: map-get($colors, "lightgrey");
  .icon:not(.icon-link) svg g path {
    stroke: map-get($colors, "lightgrey");
  }
  &:hover {
    background-color: map-get($colors, "lightgrey");
    border-color: map-get($colors, "lightgrey");
    color: map-get($colors, "mediumblue");
    .icon:not(.icon-link) svg g path {
      stroke: map-get($colors, "mediumblue");
      transition: all 0.25s;
    }
  }
}

@mixin borderless-button {
  @include t-body;

  color: map-get($colors, "lightgrey");
  font-style: italic;

  .icon svg g path {
    stroke: none;
  }

  &:hover {
    color: map-get($colors, "mediumblue");
    background-color: transparent;
    .icon svg g path {
      stroke: none;
      fill: map-get($colors, "mediumblue");
    }
  }

  @include media(tabletAndDesktop) {
    border: 0px;
  }
}
