@import "../../../../scss/main.scss";

$svg-checkmark: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.156' height='22.535' viewBox='0 0 24.156 22.535'%3E%3Cg id='Group_12' data-name='Group 12' transform='translate(2.12 2.035)'%3E%3Cpath id='Path_694' data-name='Path 694' d='M-10,0,9,10.375,0,20' transform='translate(20 10) rotate(90)' fill='none' stroke='%23f7f5f2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E";

ul.values {
  @include t-body;
  list-style: none;
  padding: 0;
  margin: 0;
}

.edit-panel {
  & > * {
    &:hover {
      opacity: 0.7;
    }
  }
}

form#add_values li.value label {
  &:after {
    content: none;
  }
}

li.value:not(.added) {
  label {
    &:after {
      // content: "+";
      content: none;
    }
  }
}

li.explanation {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}

li.value {
  display: block;
  width: 100%;
  position: relative;
  height: 6rem;
  border: solid 2px map-get($colors, "mediumblue");
  margin-bottom: 10px;
  text-transform: capitalize;

  &.selected {
    border: solid 2px map-get($colors, "white");
  }

  label {
    pointer-events: none;
    width: 100%;
    padding: 1rem 2rem 0;
    &:after,
    &:before {
      font-size: 3.5rem;
      display: block;
      float: right;
      transition: all 0.5s;
      position: absolute;
      top: 2.25rem;
      right: 2rem;
      transform: translateY(-50%);
    }

    &:before {
      content: "";
      width: 2rem;
      height: 2rem;
      top: 2.5rem;
      background-image: url($svg-checkmark);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
    }
  }
  input[type="checkbox"] {
    pointer-events: all;
    display: block !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    opacity: 0;
    cursor: pointer;
    // &:checked ~ {
    //   label:before {
    //     opacity: 1;
    //   }
    //   label:after {
    //     opacity: 0;
    //   }
    // }
    // &:hover ~ label:after {
    //   color: map-get($colors, "mediumblue");
    // }
  }
}

li.added {
  border: solid 2px white;
  &:hover {
    pointer-events: all;
    cursor: pointer;
  }
}
input.added {
  & + label {
    &:before {
      content: "-";
      width: 2rem;
      height: 2rem;
      top: 2.5rem;
      opacity: 1;
      background-image: none;
    }
  }
}

// .fingerprint-form.potential-values {
//   fieldset {
//     margin-top: 4.5rem;
//   }
// }

.fingerprint-form {
  fieldset {
    margin-top: 4.5rem;
  }
}

.add-value-button-wrap {
  width: 100%;
  position: relative;
  border-top: solid 2px map-get($colors, "mediumblue");
  border-bottom: solid 2px map-get($colors, "mediumblue");
  padding: 3rem;
  margin-bottom: 5rem;
  button {
    margin-left: 50%;
    transform: translateX(-50%);
    background: transparent;
    border: transparent;
    @include t-body-sm;
    color: map-get($colors, "lightgrey");
    .plus,
    .btn-text {
      display: block;
      width: 100%;
    }
    .plus {
      font-size: 4rem;
      width: 5rem;
      height: 5rem;
      margin: 0 auto;
      border: solid 2px map-get($colors, "lightgrey");
      border-radius: 3rem;
      padding: 0.6rem 0 0;
      margin-bottom: 1.7rem;
      .icon-arrow {
        display: block;
        margin-top: -2px;
        transform: scale(1.5) translateX(1rem) translateY(-0.2rem);
        position: absolute;
      }
    }
  }
}
.adding-a-value {
  width: 100%;
  position: relative;
  @include t-body;
  input.adding-value {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    padding: 1.35rem 2rem;
    color: map-get($colors, "lightgrey");
    &::placeholder {
      color: map-get($colors, "darkgrey");
      font-style: italic;
    }
  }
  .plus-close {
    font-size: 2rem;
    display: block;
    float: right;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    color: map-get($colors, "darkgrey");
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      color: map-get($colors, "lightblue");
    }
  }
}

input.added-value {
  display: block;
}

form.core-values .fingerprint-form-navigation {
  margin-top: 5rem;
}

//VALUES ORDERING
.values-order {
}

.valus-order-intro {
  h1 {
    @include t-header-xl;
    color: map-get($colors, "mediumblue");
  }
}

.values-order-list {
  position: relative;
  clear: both;
  z-index: 1;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0.5rem;
    left: 7.25rem;
    top: 0;

    @include media(mobile) {
      display: none;
    }
  }

  &:before {
    background-color: map-get($colors, "mediumblue");
    height: 82%;
    z-index: -2;
  }

  &:after {
    background-color: map-get($colors, "white");
    height: 20%;
    z-index: -1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 9.4rem;
    margin-bottom: 14.57rem;
    z-index: 3;

    @include media(mobile) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }

  li {
    padding-left: 17rem;
    position: relative;

    @include media(mobile) {
      padding-left: 0;
    }

    &:not(:first-of-type) {
      margin-top: 5rem;

      @include media(mobile) {
        margin-top: 4rem;
      }
    }

    .order-circle {
      display: block;
      position: absolute;
      width: 10rem;
      height: 10rem;
      border: solid 5px map-get($colors, "mediumblue");
      border-radius: 8rem;
      left: 2.5rem;
      background-color: map-get($colors, "darkblue");

      @include media(mobile) {
        display: none;
      }
    }
    &:first-of-type,
    &:last-of-type {
      .order-circle {
        width: 15rem;
        height: 15rem;
        border-color: map-get($colors, "white");
        left: 0;
      }
    }
    &:last-of-type .order-circle {
      background-color: map-get($colors, "mediumblue");
    }
    .order-line {
    }
    h2 {
      @include t-header-xl;
      margin: 0;
      text-transform: capitalize;
    }

    .explainer {
      margin-top: 3rem;
      position: relative;
      max-width: 71.3rem;
    }

    p {
      @include t-body;
      color: map-get($colors, "lightblue");
      &:first-of-type:not(:only-of-type) {
        margin-bottom: 3rem;
      }
      b {
        font-weight: 800;
        color: map-get($colors, "white");
      }
    }
  }
  .ordering-buttons {
    display: flex;
    width: 100%;
    max-width: 357.85px;
    justify-content: flex-start;
    position: relative;
    margin-top: 3rem;

    button {
      @include t-body-sm;
      display: block;
      position: relative;
      background-color: transparent;
      border: none;
      color: map-get($colors, "darkgrey");
      transition: all 0.5s;

      .icon {
        display: block;
        float: right;
      }

      .icon-arrow {
        svg path {
          transition: all 0.5s;
        }
      }

      .icon-arrow-down {
        transform: rotate(90deg);
        margin-left: 1rem;
      }

      .icon-arrow-up {
        transform: rotate(-90deg);
        margin-left: 0.25rem;
      }

      &.remove {
        span {
          display: block;
          font-weight: 800;
          float: right;
          margin-left: 1rem;
        }
      }

      &:hover {
        color: map-get($colors, "lightgrey");
        .icon-arrow {
          svg path {
            stroke: map-get($colors, "lightgrey");
          }
        }
      }
    }
  }
}

section.value-order-accuracy {
  position: relative;
  z-index: 1;

  h3 {
    @include t-header-l;
  }

  .button-row {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    button {
      padding: 0 2rem 0.5rem;
      @include t-body;
      background-color: transparent;
      border: solid 2px map-get($colors, "lightgrey");

      &:first-of-type {
        color: map-get($colors, "lightgrey");
        margin-right: 3rem;
      }

      &:last-of-type {
        color: map-get($colors, "lightblue");
        border-color: map-get($colors, "lightblue");
      }

      @include media(mobile) {
        font-size: 1.5rem;
      }
    }
  }

  .final-message {
    @include t-body-sm;
    font-style: italic;
    margin-top: 5rem;
    display: block;
    position: relative;
    color: map-get($colors, "mediumgrey");
  }
}
