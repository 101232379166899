@import "../../../scss/main.scss";

section.fingerprint {
  position: relative;

  .title-card {
    h1 {
      @include t-header-l;
      font-weight: bold;
      color: map-get($colors, "lightblue");
      margin: 0 0 4rem;
    }

    .completed {
      display: block;
      @include t-body-sm;
      color: map-get($colors, "mediumgrey");
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
}

.name {
  position: absolute;
  top: -68px;
  left: 5px;
  z-index: 100;
  font-size: 40px;
  font-weight: 700;
}

.fingerprint-wrapper {
  position: relative;

  .fingerprint-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1rem;
    max-width: 642px;
    margin: 0 0 24px 36px;

    @include media(mobile) {
      display: none;
    }

    &:before,
    &:after {
      z-index: 5;
      content: "";
      height: 100%;
      border-radius: 0.5rem;
      background-color: map-get($colors, "lightgrey");
    }

    &:before {
      width: 50%;
    }

    &:after {
      width: 5%;
    }
  }

  .fingerprint-inner-wrapper {
    padding-top: 316px;

    @include media(mobile) {
      padding-top: 0;
    }

    .list-of-values {
      min-height: 200px; // Prevent collapse while values are loading
      position: relative;
      z-index: 10;
      max-width: 600px;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: right;

      li + li {
        margin-top: 5px;
      }

      li {
        text-transform: capitalize;
        @include t-body;
        color: map-get($colors, "mediumgrey");
        position: relative;
        &:first-child,
        &:last-child {
          color: map-get($colors, "lightgrey");
          font-weight: 900;
        }

        font-size: 1.66rem;
        @media #{map-get($media, "mw700")} {
          font-size: 2rem;
        }

        @include media(mobile) {
          text-align: left;
        }
      }
    }
  }
}

section.initial-narrative,
section.final-narrative {
  .two-shapes {
    .shape-wrapper {
      height: calc((285px / 10) * 7);
      .shape-1 {
        transform: scale(70%);
      }
      .shape-2 {
        &.lines {
          transform: scale(70%) rotateZ(90deg);
          top: -50%;
        }
      }
    }
  }
}

section.craft-narrative,
.final-narrative {
  hr {
    border: solid 2px #3c78e6;
    margin-top: 5rem;
  }

  .row {
    & + .row {
      margin-top: 40px;
    }
  }

  h2 {
    @include t-header-m;
    color: map-get($colors, "lightblue");
    margin-top: 15rem;
    margin-bottom: 4rem;
  }

  h3 {
    @include t-header-m;
    color: map-get($colors, "lightblue");
  }

  .explainer,
  .tool-tip {
    @include t-body-sm;
    color: map-get($colors, "mediumgrey");
    font-style: italic;
  }

  .tool-tip {
    margin-top: 12px;
    cursor: pointer;
    transition: all 0.5s;
    &:before {
      content: "?";
      display: block;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 1.5rem;
      border: solid 1px map-get($colors, "lightgrey");
      float: left;
      margin-right: 1rem;
      text-align: center;
      line-height: 1.7rem;
      transition: all 0.5s;
    }
    &:hover:before {
      color: map-get($colors, "lightblue");
      border-color: map-get($colors, "lightblue");
    }
  }

  .narrative {
    max-width: 100%;
    border-top: solid 2px map-get($colors, "mediumblue");
    border-bottom: solid 2px map-get($colors, "mediumblue");
    margin: 0 0 5rem;
    padding: 5rem 0;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: map-get($sizes, "mwm");
    .btn {
      margin: 0;
      margin-left: 5rem;
    }
    .saved {
      display: block;
      position: relative;
      font-style: italic;
      color: map-get($colors, "mediumgrey");
    }
  }
}

.final-fingerprint {
  &.color-navy {
    .fingerprint-wrapper {
      svg {
        path {
          stroke: #33337f !important;
        }
      }
    }
  }
}

.final-narrative {
  .narrative {
    margin: 0;
    padding: 0;
    border: none;
    max-width: 80%;
  }
}

.narrative {
  max-width: 60%;
  margin: 16rem 0 5rem;

  span.locked {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0 0.5rem;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      background-color: #354499;
      height: 3rem;
      z-index: -1;
      border-radius: 0.5rem;
      top: 0.75rem;
      left: -0.15rem;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.final-narrative-button-row {
  max-width: map-get($sizes, "mwm");
  margin-top: 5rem;
  button {
    float: left;
  }
  .icon-arrow svg {
    transform: rotateZ(90deg);
  }
}

.example-narrative {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: map-get($colors, "navyblue");
    opacity: 0.9;
  }
  .example-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: map-get($colors, "mediumblue");
    width: calc(100% - 4rem);
    max-width: 72rem;
    min-height: 39.16rem;
    padding: 4.5rem 4rem;
    .close {
      position: absolute;
      display: block;
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 2rem;
      background-color: map-get($colors, "lightgrey");
      color: map-get($colors, "lightblue");
      font-size: 4rem;
      line-height: 2.5rem;
      text-align: center;
      transform: rotate(45deg);
      top: calc(3.3rem / -2);
      right: calc(3.3rem / -2);
      cursor: pointer;
    }
  }
}

.final-narrative {
  ~ .narrative {
    margin-top: 90vh;
  }
}

.narratives-footer {
  width: 100vw;
  background-color: #33337f;
  z-index: 998;
  padding: 7rem 5rem;
  position: relative;
  left: -3rem;
  span#logout {
    position: absolute;
    right: 7rem;
    width: fit-content;
    display: block;
    font-size: 1.5rem;
    color: #8b9197;
    &:hover {
      cursor: pointer;
    }
  }
  span.logo {
    position: absolute;
    @media #{map-get($media, "mw700")} {
      position: absolute;
    }
  }
}

.audio {
  position: fixed;
  z-index: 999;
  bottom: 1rem;
  right: 1rem;

  .audio-player {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: none;

    .controls {
      display: flex;
      justify-content: space-between;
    }
  }
}

span.example-value {
  background: darkblue;
  font-size: 1.55rem;
  text-decoration: underline;
}

div.example-wrap p {
  @media #{map-get($media, "mw700")} {
    width: 75%;
  }
  @include t-body-sm;
}
