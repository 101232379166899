@import "~bootstrap/scss/bootstrap";

body,
html {
  font-family: $type-body;
  font-size: 10px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

*:focus {
  border: 0px;
  outline: dotted 2px map-get($colors, "lightblue") !important;
}

.container {
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: map-get($sizes, "mws");
  padding: 0;
  display: flex;
  flex-direction: column;

  &.container-delete {
    justify-content: center;
    align-items: center;
  }

  &.container-medium {
    max-width: map-get($sizes, "mwm");
    margin: 0 0 0 3rem;
  }

  &.container-large {
    max-width: map-get($sizes, "mwl");
  }

  &.container-none {
    max-width: 100%;
  }
}

.row {
  position: relative;
  clear: both;

  &.bottom-options {
    width: 100%;
    max-width: map-get($sizes, "mws");
    margin-top: 5rem;
    padding: 0;

    > * {
      width: auto;
    }

    a {
      @include t-body-sm;
      text-decoration: none;
    }
    .total {
      @include t-body;
      font-weight: 700;
      color: map-get($colors, "darkgrey");
      flex-grow: 2;
      text-align: right;
      padding: 0 5rem 0 0;
    }
  }
}

//TEXT

.large-title {
  @include t-header-l;
  margin-bottom: 0;
  color: map-get($colors, "mediumblue");
}

.view-label {
  @include t-body-sm;
  color: map-get($colors, "darkgrey");
}

.bottom-message {
  @include sm-paragraph;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: flex-end;
}

.bottom-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin: 0;
  }
}

.content-wrapper {
  h1 {
    @include t-header-xl;

    @include media(mobile) {
      font-size: 4rem;
      line-height: auto;
    }
  }

  p {
    @include t-body;
    margin-top: 4rem;
    &:first-of-type {
      margin-top: 5rem;
    }
  }

  // > a button,
  // > button {
  //   float: left;
  // }
}

//FORMS

form {
  position: relative;
  width: 100%;
  // float: left;
  margin-top: 9rem;
  margin-bottom: 9rem;
  &.purchase-payment {
    margin-top: 6rem;
  }
  > .row:not(:first-child) {
    margin-top: 4rem;
  }
  .form-message {
    @include sm-paragraph;
    margin-top: 5rem;
    &.above-button {
      margin-top: 0;
    }
  }
  .field-description {
    @include t-body-sm;
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
  }
}

.form-group {
  @include t-body-sm;
  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
  > .form-label {
    display: block;
    position: absolute;
    text-indent: -9999px;
  }
  input[type="text"],
  input[type="password"],
  input[type="number"] {
    &.form-control {
      @include t-body-sm;
      display: block;
      border: 0px;
      padding: 1rem;
      border-bottom: solid 2px map-get($colors, "mediumblue");
      background-color: transparent;
      border-radius: 0;
      &::placeholder {
        color: map-get($colors, "darkgrey");
        transition: all 0.25s;
      }
      &:hover {
        border-color: map-get($colors, "darkgrey");
        &::placeholder {
          color: map-get($colors, "mediumblue");
        }
      }
      &:active,
      &:focus {
        border-color: map-get($colors, "darkgrey");
      }
    }
    &.read-only {
      border-color: rgba(0, 0, 0, 0);
      pointer-events: none;
    }
  }
  .error-message {
    color: map-get($colors, "red");
    @include t-body-xs;
    margin-top: 0.5rem;
  }
}

.modal-title {
  padding: 15px;
  font-size: 22px;
  line-height: 1.2;
}

.modal-footer {
  padding: 12px 30px;
  border-top: 0;

  .back-button + .back-button {
    margin-left: 20px;
  }
}
